import {
  ComponentProps,
  Dispatch,
  ReactElement,
  ReactNode,
  RefAttributes,
  SetStateAction,
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DataExportModal, { ExportModes } from '../../components/shared/data-export/DataExportModal';
import { useImperativeHandle } from 'react';
import { ActivityFilter } from '../../models/ClientForm';
import { DataJobSourceType } from '../../models/DataImport';
import Checkbox from '../../components/shared/form-control/Checkbox';
import withSlot, { SlotDefinitions } from '../../wrappers/withSlot';
import useSlot from '../../hooks/useSlots';
import DefaultSelectionBar, { DefaultSelectionBarProps } from './DefaultSelectionBar';
import Tooltip from '../../components/shared/Tooltip';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '../../components/shared/icon/DownloadIcon';
import { DownloadPdfPreferences } from '../../models/DownloadPdfPreferences';

export type DataJobSource = {
  name?: string;
  type?: DataJobSourceType;
  id?: string;
};

type Item = { id: string };

export type SelectionSpace = 'activity' | 'document';

export type SelectItemsContextType = {
  SelectionBarContent: (props: DefaultSelectionBarProps) => ReactNode | ReactElement;
  SelectAllButton: (props: { visibleItems: Item[] }) => ReactNode | ReactElement;

  space: SelectionSpace;
  downloadPreferences?: DownloadPdfPreferences;
  selection: {
    active: boolean;
    isDisabled: boolean;
    excludedIds: string[];
    selectedAll: boolean;
    selectedCount: number;
    selectionStatus: Record<string, boolean>;
    filter: ActivityFilter;
    source: DataJobSource;
    isSelected: (item: Item) => boolean;
  };
  mutateSelection: {
    clear: (clientForms: Item[]) => void;
    selectIndividual: (clientForm: Item, value: boolean) => void;
    selectMany: (clientForms: Item[], value: boolean) => void;
    selectAll: Dispatch<SetStateAction<boolean>>;
    setFilter: Dispatch<SetStateAction<ActivityFilter>>;
    setSource: Dispatch<SetStateAction<DataJobSource>>;
    setForceMode: Dispatch<SetStateAction<ExportModes | undefined | null>>;
  };
  export: {
    showingModal: boolean;
    showModal: Dispatch<SetStateAction<boolean>>;
  };
};

type SelectionElementsContextType = {
  DataExportButton: () => ReactElement | ReactNode;
  props: DefaultSelectionBarProps;
};

const SelectItemsContext = createContext<SelectItemsContextType>({
  export: null!,
  mutateSelection: null!,
  SelectAllButton: null!,
  space: 'activity',
  selection: {
    selectedAll: false,
    selectedCount: 0,
    active: false,
    excludedIds: [],
    filter: {},
    isDisabled: true,
    isSelected: () => false,
    selectionStatus: {},
    source: {},
  },
  SelectionBarContent: null!,
});
const SelectionElementsContext = createContext<SelectionElementsContextType>(null!);

export const SelectItemContextConsumer = SelectItemsContext.Consumer;

export const useItemSelection = (): SelectItemsContextType => useContext(SelectItemsContext);
export const useItemSelectionElements = (): SelectionElementsContextType => useContext(SelectionElementsContext);

type Handle = {
  setShowDataExportModal: (value: boolean, forceMode?: ComponentProps<typeof DataExportModal>['forceMode'], fileName?: string) => void;
  forceExportMode: (mode?: ComponentProps<typeof DataExportModal>['forceMode']) => void;
  selectAll: (value: boolean) => void;
  selectIndividual: (id: string) => void;
  setFilter: (value: ActivityFilter) => void;
  setSource: (value: DataJobSource) => void;
  reset: () => void;
};

type ContextProps = {
  disable?: boolean;
  space?: SelectionSpace;
  downloadPreferences?: DownloadPdfPreferences;
};

export const SelectItemContextProvider = withSlot<ContextProps & RefAttributes<Handle>, SlotDefinitions<['SelectionBar']>>(
  forwardRef(function SelectClientFormContextProviderImpl(props, ref) {
    const { disable, space = 'activity', downloadPreferences, children } = props;

    const [selectedItems, setSelectedItems] = useState<Record<string, boolean>>({});
    const [selectedAll, setSelectedAll] = useState(false);
    const [excludedSelectionIds, setExcludedSelectionIds] = useState<string[]>([]);
    const [showDataExportModal, setShowDataExportModal] = useState(false);
    const [filter, setFilter] = useState<ActivityFilter>({});
    const [source, setSource] = useState<DataJobSource>({});

    const [forceExportMode, setForceExportMode] = useState<ExportModes | undefined | null>(null);
    const [exportFileName, setExportFileName] = useState('');

    const { t } = useTranslation();

    useImperativeHandle(ref, () => ({
      setShowDataExportModal(value, forceMode, forceName) {
        setShowDataExportModal(value);
        setForceExportMode(forceMode);
        setExportFileName(forceName || '');
      },
      forceExportMode(mode) {
        setForceExportMode(mode);
      },
      selectAll(value) {
        setSelectedAll(value);
      },
      selectIndividual(id) {
        onSelectIndividual({ id }, true);
      },
      setFilter(value) {
        setFilter(value);
      },
      setSource(value) {
        setSource(value);
      },
      reset() {
        setSelectedAll(false);
        setSelectedItems({});
        setExcludedSelectionIds([]);
        setFilter({});
        setForceExportMode(null);
        setExportFileName('');
      },
    }));

    const selectedCount = useMemo(() => {
      return Object.values(selectedItems).reduce((prev, curr) => prev + (curr ? 1 : 0), 0);
    }, [selectedItems]);

    const onSelectMany = useCallback(
      (clientForms: Item[], value: boolean) => {
        const result: Record<string, boolean> = {};
        for (const activity of clientForms) {
          result[activity.id] = value;
        }

        setSelectedItems(result);

        if (!value && selectedAll) {
          setSelectedAll(false);
          setExcludedSelectionIds([]);
        }
      },
      [selectedAll],
    );

    const clearSelection = useCallback(
      (clientForms: Item[]) => {
        setSelectedAll(false);
        onSelectMany(clientForms, false);
      },
      [onSelectMany],
    );

    const onSelectIndividual = useCallback(
      (activity: Item, value: boolean) => {
        if (selectedAll) {
          setExcludedSelectionIds((prev) => {
            if (value) {
              return prev.filter((x) => x !== activity.id);
            }

            return [...prev, activity.id];
          });
        } else {
          setSelectedItems((prev) => ({ ...prev, [activity.id]: value }));
        }
      },
      [selectedAll],
    );

    useEffect(() => {
      if (!showDataExportModal) {
        setSelectedItems({});
        setSelectedAll(false);
        setExcludedSelectionIds([]);
      }
    }, [showDataExportModal]);

    const selectValues = useMemo<SelectItemsContextType>(
      () => ({
        SelectionBarContent: null!, // will be set below
        SelectAllButton: null!, // also set below

        space,
        downloadPreferences,
        selection: {
          active: !disable && (selectedAll || selectedCount > 0),
          isDisabled: !!disable,
          excludedIds: excludedSelectionIds,
          selectedAll: selectedAll,
          selectedCount: selectedCount,
          selectionStatus: selectedItems,
          filter: filter,
          source: source,
          isSelected: ({ id }) => {
            return selectedAll ? !excludedSelectionIds.includes(id) : selectedItems[id];
          },
        },
        mutateSelection: {
          clear: clearSelection,
          selectIndividual: onSelectIndividual,
          selectMany: onSelectMany,
          setFilter: setFilter,
          selectAll: setSelectedAll,
          setSource: setSource,
          setForceMode: setForceExportMode,
        },
        export: {
          showingModal: showDataExportModal,
          showModal: setShowDataExportModal,
        },
      }),
      [
        space,
        downloadPreferences,
        disable,
        selectedAll,
        selectedCount,
        excludedSelectionIds,
        selectedItems,
        filter,
        source,
        clearSelection,
        onSelectIndividual,
        onSelectMany,
        showDataExportModal,
      ],
    );

    const elementValues = useMemo<Omit<SelectionElementsContextType, 'props'>>(
      () => ({
        DataExportButton: () => (
          <Tooltip text={t('common:item-selection.buttons.export')}>
            {(tooltip) => (
              <span {...tooltip}>
                <DownloadIcon
                  className="h-6 w-6 hover:scale-105"
                  onClick={() => {
                    setShowDataExportModal(true);
                  }}
                />
              </span>
            )}
          </Tooltip>
        ),
      }),
      [t],
    );

    const selectionBarSlotEl = useSlot(props, 'SelectionBar', <DefaultSelectionBar />);

    const SelectionBarContent = useMemo(
      () =>
        function SelectionBarContentImpl(props: DefaultSelectionBarProps) {
          return (
            <SelectItemsContext.Provider value={selectValues}>
              <SelectionElementsContext.Provider value={{ ...elementValues, props }}>{selectionBarSlotEl()}</SelectionElementsContext.Provider>
            </SelectItemsContext.Provider>
          );
        },
      [elementValues, selectValues, selectionBarSlotEl],
    );

    const SelectAllButton = useMemo<(props: { visibleItems: Item[] }) => ReactNode | ReactElement>(
      () =>
        function SelectAllButtonImpl({ visibleItems }) {
          return (
            <Checkbox
              value={selectValues.selection.active}
              indeterminate={
                selectValues.selection.active && !selectValues.selection.selectedAll && selectValues.selection.selectedCount < visibleItems.length
              }
              onChange={(value) => selectValues.mutateSelection.selectMany(visibleItems, value)}
            />
          );
        },
      [selectValues.mutateSelection, selectValues.selection.active, selectValues.selection.selectedAll, selectValues.selection.selectedCount],
    );

    const value = useMemo(() => ({ ...selectValues, SelectionBarContent, SelectAllButton }), [SelectAllButton, SelectionBarContent, selectValues]);

    return (
      <SelectItemsContext.Provider value={value}>
        {children}
        <DataExportModal
          open={showDataExportModal}
          onClose={() => setShowDataExportModal(false)}
          forceMode={forceExportMode ?? undefined}
          fileName={exportFileName}
        />
      </SelectItemsContext.Provider>
    );
  }),
);
